<template>
  <div class="organization">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'organization',
  components: {
  },
  data() {
    return {}
  },
  created() {
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.organization {
  width: 100%;
  height: 100%;
}
</style>
